import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Breadcrumb from '../LandingPage/Breadcrumb';
import { colors, margins, mobileThresholdPixels, Button } from '../home/v2/StyledComponents';
import routesMap from '../../Routes';
import checkImg from '../../assets/laRubrique/check.svg';

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.l};
  }
`;

const Content = styled.div`
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.l} ${margins.m};
  }
`;

const Title = styled.h3`
  color: ${colors.navy};
  font-family: Gotham;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  margin: 15px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 100px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px;
  }
`;

const CheckImg = styled.img`
  margin-right: 12px;
  margin-top: 12px;
`;

const Check = (<CheckImg src={checkImg} alt="Check" />);

const LaRubriqueFooter = ({ category }) => (
  <Container>
    <Content>
      <Title>{category.footer.title || 'Pas le temps ?'}</Title>
      <p>
        On vous envoie un couturier :<br />
        {category.footer.bullets.map(bullet => (
          <span key={bullet}>{Check}{bullet}<br /></span>
        ))}
      </p>
      <ButtonContainer>
        <Link to={routesMap.Step1.url}>
          <Button navy>prendre RDV</Button>
        </Link>
      </ButtonContainer>
      {(category && category.breadcrumb) &&
        <Breadcrumb itemListElement={category.breadcrumb} /> }
    </Content>
  </Container>
);

LaRubriqueFooter.propTypes = {
  category: PropTypes.shape({}),
};

LaRubriqueFooter.defaultProps = {
  category: {
    footer: {
      title: 'Pas le temps de vous mettre à la couture ?',
      bullets: [
        'chez vous',
        'qui vous conseille et vous aide à ajuster votre dressing',
        'réalise les retouches dans son atelier',
        'et livre le vêtement après retouches chez vous',
      ],
    },
  },
};

export default LaRubriqueFooter;
